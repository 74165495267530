<template>
  <div :id="idLocal" class="vs-con-loading__container">
    <slot name="content" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "div-loading"
    },
    type: {
      type: String,
      default: "material"
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    isLoading(val) {
      if (val) return;
      else {
        this.closeLoadingInDiv();
      }
    }
  },
  computed: {
    isLoadingLocal: {
      get() {
        return this.isLoading;
      },
      set(val) {
        if (!val) {
          this.closeLoadingInDiv();
        } else {
          this.openLoadingInDiv();
        }
      }
    },
    idLocal() {
      return this.id;
    }
  },
  methods: {
    openLoadingInDiv() {
      this.$vs.loading({
        container: `#${this.idLocal}`,
        scale: 0.6,
        type: this.type
      });
    },
    closeLoadingInDiv() {
      this.$vs.loading.close(`#${this.idLocal} > .con-vs-loading`);
    }
  },
  mounted() {
    this.openLoadingInDiv();
  }
};
</script>
