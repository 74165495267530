<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card class="list-view-item mb-2 overflow-hidden">
    <template slot="no-body">
      <div class="vx-row item-details no-gutter">
        <!-- IMG COL -->
        <!-- <div
          class="vx-col cust-md-50 sm:w-1/6 w-full item-img-container bg-white flex items-center justify-center"
        >
          <vs-avatar
            v-if="item.image == null"
            :src="item.image ? item.image : null"
            @error="
              item.image = magittUrl + 'uploads/defaultImages/default_post.png'
            "
            :text="item.name"
            alt="content-img"
            class="grid-view-img p-2 cust-img-width def-user-img bg-primary"
          />
          <img
            v-if="item.image != null"
            :src="item.image ? item.image : null"
            @error="
              item.image = magittUrl + 'uploads/defaultImages/default_post.png'
            "
            alt="content-img"
            class="grid-view-img cust-img-width bg-white"
          />
        </div> -->

        <!-- ITEM NAME & DESC COL -->
        <div class="vx-col">
          <div class="lg:p-4 cust-height-right">
            <slot name="item-meta">
              <div class="lg:flex lg:gap-12">
                <div class="">
                  <div class="flex">
                    <div class="flex flex-row">
                      <span class="item-name font-bold fsize20 w-full">
                        {{ item.name }}
                      </span>

                      <slot name="status-buttons" class="mr-0" />
                    </div>
                  </div>

                  <div class="flex mt-2">
                    <!-- SLOT: ACTION BUTTONS -->
                    <slot name="action-buttons" />
                  </div>
                </div>

                <slot name="upload-date" />
              </div>

              <div class="">
                <p v-if="item.isOwner" class="mt-4 mb-2 fsize12 font-semibold">
                  COMUNITY OWNER
                </p>
                <p
                  v-else-if="item.isManager"
                  class="mt-4 mb-2 fsize12 font-semibold"
                >
                  COMMUNITY MANAGER
                </p>
                <p
                  v-else-if="item.isNormalUser"
                  class="mt-4 mb-2 fsize12 font-semibold"
                >
                  READER
                </p>
                <p
                  v-else-if="item.status != 0"
                  class="mt-4 mb-2 fsize12 font-semibold"
                >
                  CHANNEL ADMIN
                </p>
              </div>

              <div class="flex w-1/2">
                <p v-if="item.mobile" class="mr-8 mb-4 flex items-center">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="w-5 h-5"
                    class="text-primary mr-4"
                  />
                  <span> {{ item.mobile }}</span>
                </p>
                <p v-if="item.email" class="mb-4 flex items-center">
                  <feather-icon
                    icon="MailIcon"
                    svgClasses="w-5 h-5"
                    class="text-primary mr-4"
                  /><span> {{ item.email }}</span>
                </p>
              </div>
              <div class="flex flex-wrap mt-4">
                <div
                  v-for="userProfileDetail of item.profileDetails"
                  :key="userProfileDetail.user_profile_detailsId"
                  class="lg:w-1/2 w-full"
                >
                  <div
                    class="flex mr-4"
                    v-if="userProfileDetail.user_profile_details_value"
                  >
                    <li class="font-semibold">
                      {{ userProfileDetail.profile_detail_master_profile_name }}
                    </li>
                    <p class="ml-2">
                      {{ userProfileDetail.user_profile_details_value }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <span
                  v-if="item.RequestStatus"
                  class="mt-4 mb-2 fsize12 font-semibold"
                >
                  Status:
                </span>
                <span>{{ item.RequestStatus }}</span>
              </div>
              <div class="mt-4">
                <span
                  v-if="item.ldapid"
                  class="mt-4 mb-2 fsize12 font-semibold"
                >
                  LDAP ID:
                </span>
                <span>{{ item.ldapid }}</span>
              </div>
              <div class="mt-4">
                <span v-if="item.role" class="mt-4 mb-2 fsize12 font-semibold">
                  New Role:
                </span>
                <span>{{ item.role }}</span>
              </div>

              <div class="mt-4">
                <span
                  v-if="item.request_by"
                  class="mt-4 mb-2 fsize12 font-semibold"
                >
                  Request from:
                </span>
                <span>{{ item.request_by }}</span>
              </div>
            </slot>
            <!-- <DivLoader
              :id="'user-' + item.objectID"
              :isLoading="itemLocal.groupsLoading"
            >
              <template slot="content"> -->
            <slot name="channel-list" />
            <!-- </template>
            </DivLoader> -->
          </div>
        </div>

        <!-- PURCHASE COL -->
      </div>
    </template>
  </vx-card>
</template>

<script>
import DivLoader from "@/Core/Views/CommonPlugins/div-loader/DivLoader";
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const profileRepository = RepositoryFactory.get("profile");

export default {
  components: {
    DivLoader,
  },
  data() {
    return {
      token: {},
      userDetails: {},
      initialRole: "admin",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    itemLocal() {
      return this.item;
    },
  },
  methods: {
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    additemInCart(item) {
      this.$store.dispatch("eCommerce/additemInCart", item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push("/apps/eCommerce/checkout").catch(() => {})
        : this.additemInCart(item);
    },
    async check(role) {
      return role === this.initialRole;
    },
  },
  async created() {
    let token = jwt.decode(localStorage.getItem("token"));
    let payload = {
      userData: {
        id: token.userID,
      },
      communityData: {
        communityName: currentCommunityName.communityName,
      },
    };
    const { data } = await profileRepository.getAllUserData(payload);
    if (data[0].communityManager_Is_owner == 1) {
      this.initialRole = "superadmin";
    } else if (data[0].communityManager_UserId != null) {
      this.initialRole = "admin";
    } else if (data[0].usersDetails_Password == null) {
      this.initialRole = "normal";
    } else {
      this.initialRole = "editor";
    }
  },
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .item-name,
  .item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-name {
    -webkit-line-clamp: 2;
  }

  .item-description {
    -webkit-line-clamp: 5;
  }

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  li {
    margin-bottom: 1rem;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
@media (max-width: 1900px) {
  .cust-img-width {
    width: 80% !important;
  }
}
.cust-height-right {
  min-height: 170px;
}
.cust-md-50 {
  margin-block-end: auto;
  margin-top: 18px;
}
</style>
